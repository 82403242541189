/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from 'react-emotion';
import { Box, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import ButtonPrimary, { ButtonSecondary } from '../components/Buttons';

const imgStyle = css`
  border-radius: 5px;
  width: 240px;
  height: 106px;
  margin: 1em;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default ({ data }) => {
  const myData = data.allContentJson.edges[0].node.index;
  const imageData = data.file.childImageSharp
  return (
    <PageWrapper>
      <>
        <Box bg={colors.primary}>
          <Box
            width={[1, 1, '1200px']}
            m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']}
            px={[3, 3, 0]}
            color={colors.secondary}
            textAlign="center"
          >
            <Img
            className={imgStyle}
            alt="Picture of X"
            fluid={imageData.fluid}
          />
            {/*<h1>{myData.title}</h1>*/}
            <h3>{myData.subtitle}</h3>
            <Flex justifyContent="center" wrap={['wrap', 'wrap', 'nowrap']}>
              <Box m={[1]} width={[1, 1 / 2, 1 / 4]}>
                <Link to="/about">
                  <ButtonPrimary>About Our Lodge</ButtonPrimary>
                </Link>
              </Box>
              <Box m={[1]} width={[1, 1 / 2, 1 / 4]}>
                <Link to="/news/how-to-become-a-mason">
                  <ButtonPrimary>How to become a Mason</ButtonPrimary>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box bg={colors.primary} py={[3, 3, 4]}>
          <Box
            width={[1, 1, '1200px']}
            m="0 auto"
            px={[3, 3, 0]}
            color={colors.secondary}
            textAlign="center"
          >
            <h2>Stated Meetings</h2>
            <p>Second Wednesday of the Month</p>
            <p>6pm Dinner</p>
            <p>7pm Lodge Opens</p>
            <h2>Find Us</h2>
            <p>San Antonio Scottish Rite Theater</p>
            <p>308 Ave E, San Antonio, TX 78205</p>
            <p>(210) 225-7072</p>
          </Box>
        </Box>
      </>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query contentQuery {
    allContentJson {
      edges {
        node {
          index {
            title
            subtitle
          }
        }
      }
    },
    file(relativePath: { eq: "cropped-Alamo-Mobile-Logo.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;